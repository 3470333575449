import * as React from "react";
import {useEffect} from "react";
import "../i18n";
import Seo from "../components/shared/Seo";
import * as styles from "./SuccessfulRequestPage.module.css";
import checked from "../images/icons/checked.svg";
import {Link} from "gatsby";
import Layout from "../components/Layout/Layout";
import {useTranslation} from "react-i18next";
import {useState} from "react";

export default function SuccessfulRequestPage() {
    const {t} = useTranslation();
    let [message, setMessage] = useState(t("successful_request_page.success_message.contact_us"));
    useEffect(() => { /* EQ To Component did mount */
        const state = window?.history?.state
        setMessage(!state?.successMessage ?  message : t(state.successMessage))
    },[message, t]);
    return (
        <Layout>
            <Seo title="Successful Request" description="Successful Request" canonicalPath="/successful-request"/>
            <div className={styles.successful_request_container}>
                <img src={checked} alt="Checked" className={styles.checked_icon}/>
                <p className={styles.successful_request_main_title}>{message}</p>
                <p className={styles.successful_request_small_text}>{t("successful_request_page.thanking_message")}</p>
                <Link to="/" className={styles.successful_request_btn}>
                    {t("successful_request_page.back_home")}
                </Link>
            </div>
        </Layout>
    );
}
